<template>
  <div class="q-ma-md q-gutter-sm">
    <q-card>
      <q-card-section>
        <h4>{{ getRes("Form.Section.ResubmitPricingDocumentsOffer") }}</h4>
      </q-card-section>
      <q-separator />
      <q-card-section>
        <div class="row no-wrap" v-for="(row, index) in fields1" :key="index">
          <span class="col-auto text-bold q-mr-md" style="font-size: 17px">
            {{ `${row.key} :` }}
          </span>
          <span class="col-auto" style="font-size: 17px">
            {{ row.value }}
          </span>
        </div>
        <pro-folders class="q-ma-md" :folders="folders1" show-download-button />
      </q-card-section>
    </q-card>
    <q-card>
      <q-card-section>
        <div class="row justify-between items-center">
          <h4>{{ getRes("Form.Field.SubmitOfferResponse") }}</h4>
          <q-btn
            no-caps
            outline
            color="primary"
            v-if="!isSubmitted && checkClosingDate()"
            :label="getRes('Form.Section.SubOffer')"
            :to="handleSubmit()"
          />
        </div>
      </q-card-section>
      <q-separator v-if="isSubmitted" />
      <q-card-section v-if="isSubmitted">
        <div class="row no-wrap" v-for="(row, index) in fields2" :key="index">
          <span class="col-auto text-bold q-mr-md" style="font-size: 17px">
            {{ `${row.key} :` }}
          </span>
          <span class="col-auto" style="font-size: 17px">
            {{ row.value }}
          </span>
        </div>
        <pro-folders class="q-ma-md" :folders="folders2" show-download-button />
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
/*eslint-disable*/
//import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProFolders from "../../../../components/PROSmart/ProFolders";

export default {
  components: { ProDeckCard, ProDeck, ProFolders },
  data() {
    return {
      fields1: [],
      fields2: [],
      folders1: [],
      folders2: [],
      isSubmitted: false,
      closingDate: {},
    };
  },

  methods: {
    handleSubmit() {
      return {
        name: "DocumentViewPTNForm",
        params: {
          mode: "Edit",
          code: "S_T_SubmitPtnOffer",
          ptnId: this.$route.params.ptnId,
        },
      };
    },

    formatDate(date) {
      return this.$proSmart.common.getFormattedDate(new Date(date + "0800"));
    },

    checkClosingDate() {
      return this.closingDate >= new Date();
    },
  },

  async beforeMount() {
    const { ptnId, id: tenderId } = this.$route.params;

    const getArr = (files) => {
      return files.map((object) => ({
        folderDescription: this.getRes("Form.Section.BoqPriceTemplate"),
        ...object,
      }));
    };

    const {
      documentRef,
      closingDate,
      ptnStatus,
      BoqTemplate: { fileList: files },
    } = await this.$proSmart.documentUi.getTendererPtnById(
      this,
      parseInt(ptnId),
      parseInt(tenderId)
    );
    this.closingDate = new Date(closingDate).setSeconds(59);
    this.fields1.push({
      key: this.getRes("Form.Field.DocRef"),
      value: documentRef,
    });
    this.fields1.push({
      key: this.getRes("Form.Field.Clodate"),
      value: this.$proSmart.common.getFormattedDate(
        new Date(closingDate + "+0800")
      ),
    });
    this.fields1.push({
      key: this.getRes("Form.Field.RequestId"),
      value: ptnId,
    });
    this.fields1.push({
      key: this.getRes("Form.Field.Status"),
      value: ptnStatus,
    });

    this.$set(this, "folders1", getArr(files));

    const {
      PtnOfferSubmission,
      submissionDate,
    } = await this.$proSmart.documentUi.getTendererPtnOfferByTenderer(
      this,
      parseInt(ptnId),
      parseInt(tenderId)
    );

    if (submissionDate && PtnOfferSubmission) {
      this.isSubmitted = true;
      this.fields2.push({
        key: this.getRes("Form.Field.Submission"),
        value: this.$proSmart.common.getFormattedDate(
          new Date(submissionDate + "+0800")
        ),
      });
      this.$set(this, "folders2", getArr(PtnOfferSubmission.fileList));
    }
  },
};
</script>
